import PropTypes from 'prop-types';
import classnames from 'classnames';
import {
  useLocation, Switch, Route, NavLink, Redirect,
} from 'react-router-dom';

import { getMainPath } from '../helpers';
import InstantWin from './instant_win';
import FinalPrize from './final_prize';
import MyCodes from './my_codes';
import SportCasinoComponents from './sport_casino_components';
import Links from './links';

const subsections = [
  { label: 'CTC_ZONE_FINAL_PRIZE', slug: 'final-prize', component: FinalPrize },
  { label: 'CTC_ZONE_INSTANT_WIN', slug: 'instant-win', component: InstantWin },
  { label: 'CTC_ZONE_MY_CODES', slug: 'my-codes', component: MyCodes },
];

const isActive = (mainPath, slug, i) => [
  ...(i === 0 ? [mainPath] : []),
  `${mainPath}/${slug}`,
];

const Tabs = ({
  competition, codeList, components,
}) => {
  const { pathname } = useLocation();
  const mainPath = getMainPath(competition.competitionType);
  const fullTime = !['OPEN', 'INPLAY'].includes(competition.status);
  const tabsClasses = classnames(
    'site-main-nav-wrapper', 'crack-the-code__tabs', { 'crack-the-code__tabs--full-time': fullTime },
  );

  if (fullTime && !pathname.includes(subsections[2].slug)) {
    return <Redirect to={`${mainPath}/${subsections[2].slug}`} />;
  }

  return (
    <>
      <div className={tabsClasses}>
        <div className="crack-the-code__slider">
          { subsections.map(({ label, slug }, i) => (
            <NavLink
              key={slug}
              to={`${mainPath}/${slug}`}
              className="site-main-nav__item"
              isActive={() => isActive(mainPath, slug, i).includes(pathname)}
              activeClassName="selected"
            >
              <span className="crack-the-code__tab site-main-nav__item--link bvs-link">
                {competition.descriptions?.[label]}
                {codeList.length > 0 && i === 2 && (
                  <span className="crack-the-code__tab-badge">
                    {codeList.length < 100 ? codeList.length : '99+'}
                  </span>
                )}
              </span>
            </NavLink>
          )) }
        </div>
      </div>
      <Switch>
        { subsections.map(({ slug, component: Component }, i) => (
          <Route
            exact
            key={slug}
            path={isActive(mainPath, slug, i)}
          >
            <Component competition={competition} codeList={codeList} />
          </Route>
        )) }
      </Switch>
      {!pathname.includes(subsections[2].slug) && (
        <SportCasinoComponents components={components} />
      )}
      <Links descriptions={competition.descriptions} />
    </>
  );
};

Tabs.propTypes = {
  competition: PropTypes.instanceOf(Object).isRequired,
  codeList: PropTypes.instanceOf(Array).isRequired,
  components: PropTypes.instanceOf(Array).isRequired,
};

export default Tabs;
