import PropTypes from 'prop-types';

const EventClose = ({ competition: { descriptions } }) => (
  <div className="ctc-final-event__event">
    <span className="ctc-final-event__start-info">
      {descriptions.CTC_FP_END_OF_EVENT}
    </span>
  </div>
);

EventClose.propTypes = {
  competition: PropTypes.instanceOf(Object).isRequired,
};

export default EventClose;
