import PropTypes from 'prop-types';
import Buttons from './buttons';

const CodeListEmpty = ({ descriptions }) => (
  <div className="ctc-mc__no-codes">
    <span className="ctc-mc__no-codes-msg">
      {descriptions.CTC_MYCODES_NO_CODES}
    </span>
    <Buttons descriptions={descriptions} />
  </div>
);

CodeListEmpty.propTypes = {
  descriptions: PropTypes.instanceOf(Object).isRequired,
};

export default CodeListEmpty;
