import PropTypes from 'prop-types';
import { formatDate } from '../../helpers';

const InplayWithoutScoreboard = ({
  competition: {
    competitionEndTime, descriptions, competitionType,
  },
}) => (
  <div className="ctc-final-event__event">
    {competitionType === 'RAFFLE'
      ? (
        <>
          <span className="ctc-final-event__start">
            {descriptions.CTC_ZONE_END}
            {' '}
            {formatDate(competitionEndTime, { longFormat: true })}
          </span>
          <span className="ctc-final-event__start-info">
            {descriptions.CTC_ZONE_SCOREBOARD_NOT_AVAILABLE}
          </span>
        </>
      ) : (
        <span className="ctc-final-event__start ctc-final-event__start--large">
          {descriptions.CTC_ZONE_SCOREBOARD_NOT_AVAILABLE}
        </span>
      )}
  </div>
);

InplayWithoutScoreboard.propTypes = {
  competition: PropTypes.instanceOf(Object).isRequired,
};

export default InplayWithoutScoreboard;
