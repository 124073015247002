import PropTypes from 'prop-types';
import Banners from 'bv-banners';
import InstantWinHeader from './instant_win_header';

const InstantWin = ({ competition }) => (
  <>
    <div className="site-top-banner-wrapper">
      <Banners location={competition.competitionType === 'RAFFLE' ? 'RaffleInstantWin' : 'CtcInstantWin'} />
    </div>
    <InstantWinHeader competition={competition} />
  </>
);

InstantWin.propTypes = {
  competition: PropTypes.instanceOf(Object).isRequired,
};

export default InstantWin;
