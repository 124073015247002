import { useState } from 'react';
import PropTypes from 'prop-types';
import { SeoTitle } from 'bv-components';
import { useEventBus } from 'bv-hooks';
import Tabs from './tabs';

const Container = ({
  initialCompetition, initialProgress, components,
}) => {
  const [competition, setCompetition] = useState(initialCompetition);
  const [codeList, setCodeList] = useState(initialProgress?.codes || []);

  useEventBus(
    initialCompetition?.pushAddresses?.competition,
    (err, newData) => {
      if (err) return;
      setCompetition((currentData) => ({ ...currentData, ...newData.body }));
    },
    [initialCompetition],
  );

  useEventBus(
    initialProgress?.pushAddresses?.progress,
    (err, newData) => {
      if (err || !newData.body?.codes) return;
      setCodeList((currentData) => ([...newData.body.codes, ...currentData]));
    },
    [initialProgress],
  );

  if (!competition || !competition.descriptions) return null;

  return (
    <section
      className={`crack-the-code crack-the-code--${initialCompetition.competitionType?.toLowerCase()}`}
    >
      <SeoTitle
        tag="h2"
        defaultHeading={initialCompetition.descriptions?.CTC_ZONE_TITLE || ''}
      />
      <Tabs
        competition={competition}
        codeList={codeList}
        components={components}
      />
    </section>
  );
};

Container.propTypes = {
  initialCompetition: PropTypes.instanceOf(Object).isRequired,
  initialProgress: PropTypes.instanceOf(Object).isRequired,
  components: PropTypes.instanceOf(Array).isRequired,
};

export default Container;
