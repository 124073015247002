import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Button } from 'bv-components';

import CodeList from './code_list';
import CodeListEmpty from './code_list_empty';
import CodeListCounter from './code_list_counter';

const isActive = (code, flatWinCode, status) => (
  !code.codeValue.flat().some((el, i) => (
    ['OPEN', 'INPLAY'].includes(status)
      ? el < flatWinCode[i]
      : el !== flatWinCode[i]
  ))
);

const addCodes = ({ winningCode, status }, setCodes, codeList) => {
  const flatWinCode = winningCode?.flat() || Array(6).fill(0);
  setCodes(codeList.map((code) => ({
    ...code,
    active: isActive(code, flatWinCode, status),
  })).sort((a, b) => b.active - a.active));
};

const CodeListContainer = ({ competition, codeList }) => {
  const [visibleCodes, setVisibleCodes] = useState(5);
  const [codes, setCodes] = useState(null);

  useEffect(() => {
    if (codes === null) {
      addCodes(competition, setCodes, codeList);
    } else {
      setTimeout(() => { addCodes(competition, setCodes, codeList); }, 1000);
    }
  }, [competition.winningCode, codeList]);

  if (!codes) return null;
  if (!codes.length) return <CodeListEmpty descriptions={competition.descriptions} />;

  const showMoreBtn = visibleCodes < codes.length;

  return (
    <>
      <div
        className={classnames('ctc-mc__codes-list', {
          'ctc-mc__codes-list--with-btn': showMoreBtn,
        })}
      >
        <CodeListCounter codes={codes} competition={competition} />
        <CodeList codes={codes} visibleCodes={visibleCodes} />
      </div>
      {showMoreBtn && (
        <Button
          type="button"
          onClick={() => setVisibleCodes((nr) => nr + 5)}
          className="bvs-button-card ctc-mc__see-more"
          label={competition.descriptions.CTC_MYCODES_SEE_MORE}
        />
      )}
    </>
  );
};

CodeListContainer.propTypes = {
  competition: PropTypes.instanceOf(Object).isRequired,
  codeList: PropTypes.instanceOf(Array).isRequired,
};

export default CodeListContainer;
