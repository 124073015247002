import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Icon } from 'bv-components';
import Buttons from './buttons';

const InstantWinHeader = ({
  competition: {
    competitionType,
    sportId,
    descriptions,
    instantWinningConfig,
  },
}) => (
  <div className="ctc-iw">
    {competitionType !== 'RAFFLE' && (
      <div className="ctc-steps__icons">
        {[1, 2].map((nr) => (
          <Icon
            key={nr}
            id={`sport-${sportId}`}
            className={`ctc-steps__icon-${nr}`}
            type="ctc-steps-icon"
          />
        ))}
      </div>
    )}
    <div className="ctc-iw__text">{descriptions?.CTC_IW_MESSAGE}</div>
    <div className="ctc-iw__types">
      {instantWinningConfig.map(({
        formattedPrize, example, highlighted, description, rewardType,
      }) => (
        <div className="ctc-iw__type">
          <div className="ctc-iw__type-name">{description}</div>
          <div className="ctc-iw__numbers-outline">
            <div className="ctc-iw__numbers-bg">
              {example.map((nr, i) => (
                <span className={classnames('ctc-iw__nr', { 'ctc-iw__nr--highlighted': highlighted[i] })}>
                  {nr}
                </span>
              ))}
            </div>
          </div>
          <div className="ctc-iw__type-prize">
            {rewardType === 'NUMBER'
              ? `${formattedPrize} ${descriptions.CTC_IW_FREE_SPIN}`
              : `${formattedPrize} ${descriptions.CTC_IW_FREE_BET}`}
          </div>
        </div>
      ))}
    </div>
    <Buttons descriptions={descriptions} />
  </div>
);

InstantWinHeader.propTypes = {
  competition: PropTypes.shape({
    competitionType: PropTypes.string.isRequired,
    sportId: PropTypes.number.isRequired,
    descriptions: PropTypes.instanceOf(Object).isRequired,
    instantWinningConfig: PropTypes.instanceOf(Array).isRequired,
  }).isRequired,
};

export default InstantWinHeader;
