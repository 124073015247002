import PropTypes from 'prop-types';
import withLazyLoad from 'bv-lazy-load';

const HomeComponentsLazy = withLazyLoad()('homeComponents');

const SportCasinoComponents = ({ components }) => (
  <HomeComponentsLazy components={components} />
);

SportCasinoComponents.propTypes = {
  components: PropTypes.instanceOf(Array).isRequired,
};

export default SportCasinoComponents;
