import PropTypes from 'prop-types';
import { Button } from 'bv-components';
import { useLoggedIn } from 'bv-hooks';
import { getMainPath } from '../helpers';

const label = (descriptions, codesNr) => {
  if (!descriptions) return '';
  switch (codesNr) {
    case 0: return descriptions.CTC_FP_NO_CODES;
    case 1: return descriptions.CTC_FP_SEE_MY_ONE_CODE;
    default: return descriptions.CTC_FP_SEE_MY_CODES
      ?.replace('<<account_total_codes>>', codesNr);
  }
};

const Buttons = ({ descriptions, codesNr, type }) => {
  const loggedIn = useLoggedIn();

  return (
    <div className="crack-the-code__buttons-container">
      <Button
        type="button"
        to={`/pages/${descriptions.CTC_ZONE_LEARN_MORE_SLUG}`}
        className="bvs-button-card active"
        label={descriptions.CTC_ZONE_LEARN_MORE}
      />
      {(codesNr || codesNr === 0) && type && loggedIn && (
        <Button
          type="button"
          to={`${getMainPath(type)}/my-codes`}
          className="bvs-button-card"
          label={label(descriptions, codesNr)}
        />
      )}
    </div>
  );
};

Buttons.propTypes = {
  descriptions: PropTypes.instanceOf(Object).isRequired,
  codesNr: PropTypes.number,
  type: PropTypes.string,
};

Buttons.defaultProps = {
  codesNr: null,
  type: null,
};

export default Buttons;
