import PropTypes from 'prop-types';
import withSuspense from 'bv-with-suspense';
import { hasSportsbook } from 'global-config';
import EventOpen from './event_open';
import EventClose from './event_close';
import InplayWithoutScoreboard from './inplay_without_scoreboard';

const InplayWithScoreboard = withSuspense(() => import('./inplay_with_scoreboard'));

const getEventClockComponent = ({ status, finalEvent }) => {
  const disableScoreboard = finalEvent?.disableScoreboard ?? true;

  switch (status) {
    case 'OPEN':
      return EventOpen;
    case 'INPLAY':
      return disableScoreboard || !hasSportsbook
        ? InplayWithoutScoreboard
        : InplayWithScoreboard;
    case 'SETTLED':
    case 'CLOSED':
    case 'AUDIT':
      return EventClose;
    default:
      return null;
  }
};

const EventClock = ({ competition }) => {
  const Component = getEventClockComponent(competition);

  return <Component competition={competition} />;
};

EventClock.propTypes = {
  competition: PropTypes.instanceOf(Object).isRequired,
};

export default EventClock;
