import PropTypes from 'prop-types';
import classnames from 'classnames';
import { TransitionGroup, CSSTransition } from 'react-transition-group';

const EventCodeGroup = ({ codeGroup, group: { iconClass, title, codes }, type }) => {
  const animationIconClassName = classnames(
    'ctc-final-event__icon',
    'ctc-final-event__icon--nr',
    {
      [`is-${iconClass}`]: iconClass,
      'is-main is-sports': !iconClass,
    },
  );

  return (
    <div className="ctc-final-event__code-group">
      <span className="ctc-final-event__group-title">
        {iconClass && <span className={`ctc-final-event__icon is-${iconClass}`} />}
        {title}
      </span>
      {type !== 'RAFFLE' && (
        <div className="ctc-final-event__ladder">
          <span className="ctc-final-event__ladder-1" />
          <span className="ctc-final-event__ladder-2" />
          <span className="ctc-final-event__ladder-3" />
          <span className="ctc-final-event__ladder-4" />
        </div>
      )}
      <div className="ctc-final-event__numbers">
        {codes.map((code, i) => {
          const digit = codeGroup?.[i]?.toString() || '?';

          return (
            <div key={code.order} className="ctc-final-event__nr-border">
              <TransitionGroup className="ctc-final-event__nr-bg">
                <CSSTransition
                  key={`${code.order}_${digit}`}
                  timeout={2000}
                  classNames="ctc-final-event__nr-wrapper"
                >
                  <span className="ctc-final-event__nr-wrapper">
                    <span className="ctc-final-event__nr">
                      { digit }
                    </span>
                    <span className={animationIconClassName} />
                  </span>
                </CSSTransition>
              </TransitionGroup>
            </div>
          );
        })}
      </div>
    </div>
  );
};

EventCodeGroup.propTypes = {
  type: PropTypes.string.isRequired,
  codeGroup: PropTypes.instanceOf(Array),
  group: PropTypes.instanceOf(Object),
};

EventCodeGroup.defaultProps = {
  codeGroup: null,
  group: { iconClass: '', title: '', codes: [] },
};

export default EventCodeGroup;
