import { getJSON } from 'bv-fetch';
import { locale } from 'bv-i18n';

export const fetchCTCCompetitions = (type) => (
  getJSON('api/ctc_competitions', { type })
);

export const fetchCTCProgress = (type, loggedIn) => (
  loggedIn
    ? getJSON('api/ctc_progress', { codeFilter: 'OVERALL', type })
    : Promise.resolve({})
);

export const fetchCTCComponents = (type, loggedIn) => (
  getJSON('/sportsbook_components/home_components/components', {
    c: locale(),
    l: loggedIn,
    sport_id: type === 'raffle' ? -3 : -2,
  }).catch(() => ([]))
);

export const fetchDictionary = (sportId) => (
  getJSON(`/bv_api/dictionary/${locale()}/${sportId}`)
);
