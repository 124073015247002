import PropTypes from 'prop-types';

import { formatDate } from '../../helpers';

const EventOpen = ({
  competition: {
    competitionEndTime, finalEvent, competitionType, descriptions,
  },
}) => (
  <div className="ctc-final-event__event">
    <span className="ctc-final-event__start">
      {competitionType === 'RAFFLE'
        ? `${descriptions.CTC_ZONE_END} ${formatDate(competitionEndTime, { longFormat: true })}`
        : formatDate(finalEvent?.eventStartTime)}
    </span>
    <span className="ctc-final-event__start-info">
      {descriptions.CTC_FP_KICK_OFF}
    </span>
  </div>
);

EventOpen.propTypes = {
  competition: PropTypes.instanceOf(Object).isRequired,
};

export default EventOpen;
