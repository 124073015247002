import PropTypes from 'prop-types';
import { ListLinks, ListRow } from 'bv-components';

const Links = ({ descriptions }) => (
  <ListLinks className="crack-the-code__links">
    <ListRow
      to={`/pages/${descriptions.CTC_ZONE_TERMS_AND_CONDITIONS_SLUG}`}
      label={descriptions.CTC_ZONE_TERMS_AND_CONDITIONS}
      hasArrow
    />
  </ListLinks>
);

Links.propTypes = {
  descriptions: PropTypes.instanceOf(Object).isRequired,
};

export default Links;
