import PropTypes from 'prop-types';
import { Icon } from 'bv-components';

const Steps = ({
  type, competition: { descriptions = {}, competitionType, sportId },
}) => (
  <div className="ctc-steps">
    {competitionType !== 'RAFFLE' && (
      <div className="ctc-steps__icons">
        {[1, 2].map((nr) => (
          <Icon
            key={nr}
            id={`sport-${sportId}`}
            className={`ctc-steps__icon-${nr}`}
            type="ctc-steps-icon"
          />
        ))}
      </div>
    )}
    <div className="ctc-steps__steps">
      {[1, 2, 3].map((i) => (
        <div key={i} className={`ctc-steps__step ctc-steps__step--${i}`}>
          <span className="ctc-steps__step-nr">{i}</span>
          <span className="ctc-steps__step-title">
            {descriptions[`CTC_${type}_STEP_${i}_TITLE`]}
          </span>
          <span className="ctc-steps__step-subtitle">
            {descriptions[`CTC_${type}_STEP_${i}_SUBTITLE`]}
          </span>
        </div>
      ))}
    </div>
  </div>
);

Steps.propTypes = {
  type: PropTypes.string.isRequired,
  competition: PropTypes.instanceOf(Object).isRequired,
};

export default Steps;
