import PropTypes from 'prop-types';
import { TransitionGroup, CSSTransition } from 'react-transition-group';

const CodeListCounter = ({ codes, competition }) => {
  const activeCodes = codes.filter((code) => code.active).length;

  return (
    competition.status !== 'OPEN'
      ? (
        <span className="ctc-mc__codes-counter ctc-mc__codes-counter--with-padding">
          <TransitionGroup className="ctc-mc__codes-counter-rotate">
            <CSSTransition
              key={activeCodes}
              timeout={750}
              classNames="ctc-mc__codes-counter-bg"
            >
              <span key={activeCodes} className="ctc-mc__codes-counter-bg">
                <span className="ctc-mc__codes-counter-nr">{activeCodes}</span>
              </span>
            </CSSTransition>
          </TransitionGroup>
          <span className="ctc-mc__codes-counter-text">
            {competition.descriptions.CTC_MYCODES_ACTIVE_CODES}
          </span>
        </span>
      ) : (
        <span className="ctc-mc__codes-counter">
          <span className="ctc-mc__codes-counter-text">
            {competition.descriptions?.CTC_MYCODES_MY_CODES
              ?.replace('<<account_total_codes>>', codes.length) || ''}
          </span>
        </span>
      )
  );
};

CodeListCounter.propTypes = {
  codes: PropTypes.instanceOf(Array).isRequired,
  competition: PropTypes.instanceOf(Object).isRequired,
};

export default CodeListCounter;
