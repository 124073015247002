import PropTypes from 'prop-types';
import { v as bvVar } from 'bv';
import { Redirect } from 'react-router-dom';
import { ContentLayout, Spinner } from 'bv-components';
import { useFetch, useLoggedIn } from 'bv-hooks';

import Container from './container';
import {
  fetchCTCCompetitions,
  fetchCTCComponents,
  fetchCTCProgress,
} from '../api';

const App = ({ type }) => {
  const loggedIn = useLoggedIn();
  const [competition, loadingCompetition] = useFetch(
    () => fetchCTCCompetitions(type),
    [type],
  );
  const [progress, loadingProgress] = useFetch(
    () => fetchCTCProgress(type, loggedIn),
    [type, loggedIn],
  );
  const [components, loadingComponents] = useFetch(
    () => fetchCTCComponents(type, loggedIn),
    [type, loggedIn],
  );

  if (loadingCompetition || loadingProgress || loadingComponents) return <Spinner />;
  if (!bvVar('crackTheCode')?.[type] || !competition?.data) return <Redirect to="/" />;

  return (
    <ContentLayout>
      <Container
        initialCompetition={competition.data}
        initialProgress={progress.data}
        components={components}
      />
    </ContentLayout>
  );
};

App.propTypes = {
  type: PropTypes.string.isRequired,
};

export default App;
