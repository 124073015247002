import { locale } from 'bv-i18n';
import { v as bvVar } from 'bv';

const SHORT_FORMAT = {
  day: 'numeric',
  month: 'short',
  hour: '2-digit',
  minute: '2-digit',
};

const LONG_FORMAT = {
  hour: 'numeric',
  minute: '2-digit',
  dayPeriod: 'narrow',
  weekday: 'long',
  day: 'numeric',
  month: 'long',
};

export const formatDate = (str, options = {}) => {
  if (!str) return '';

  const date = new Date(str);
  if (!date.toLocaleDateString) return '';

  return date.toLocaleDateString(locale(), options.longFormat ? LONG_FORMAT : SHORT_FORMAT);
};

export const getMainPath = (type) => bvVar('crackTheCode').paths[type.toLowerCase()];
