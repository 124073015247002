import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Button } from 'bv-components';
import { useLoggedIn } from 'bv-hooks';
import Banners from 'bv-banners';
import FinalEvent from './final_event';
import CodeListContainer from './code_list_container';

const MyCodes = ({ competition, codeList }) => {
  const loggedIn = useLoggedIn();
  const codeLength = competition.codesConfiguration?.groups
    ?.reduce((prev, curr) => (prev + curr.codes.length), 0) || 0;

  return (
    <>
      <div className="site-top-banner-wrapper">
        <Banners location={competition.competitionType === 'RAFFLE' ? 'RaffleMyCodes' : 'CtcMyCodes'} />
      </div>
      <div className="ctc-mc">
        <div className="ctc-mc__final">
          <FinalEvent competition={competition} />
        </div>
        <div
          className={classnames('ctc-mc__codes', {
            'ctc-mc__codes--with-list': loggedIn && codeList.length,
          })}
        >
          <div className="ctc-mc__codes-bg">
            {[...Array(codeLength).keys()].map((i) => <span key={i + 1} />)}
          </div>
          {loggedIn
            ? <CodeListContainer competition={competition} codeList={codeList} />
            : (
              <div className="ctc-mc__login">
                <Button
                  type="button"
                  to="/login"
                  className="bvs-button-card active"
                  label={competition.descriptions.CTC_MYCODES_LOGIN}
                />
                <span className="ctc-mc__login-msg">
                  {competition.descriptions.CTC_MYCODES_SEE_YOUR_CODES}
                </span>
              </div>
            )}
        </div>
      </div>
    </>
  );
};

MyCodes.propTypes = {
  competition: PropTypes.instanceOf(Object).isRequired,
  codeList: PropTypes.instanceOf(Array).isRequired,
};

export default MyCodes;
