import PropTypes from 'prop-types';
import classnames from 'classnames';
import FlipMove from 'react-flip-move';

const CodeList = ({ codes, visibleCodes }) => (
  <FlipMove appearAnimation="elevator">
    {codes.slice(0, visibleCodes).map(({
      id, codeValue, instantWin, active,
    }) => (
      <div
        className={`ctc-mc__code-row ${active ? '' : 'lost'}`}
        key={id}
      >
        <div className="ctc-mc__code-bg">
          <div className="ctc-mc__code">
            {codeValue.map((group, g) => (
              // eslint-disable-next-line react/no-array-index-key
              <span key={g} className="ctc-mc__code-group">
                {group.map((digit, d) => (
                  <span
                    // eslint-disable-next-line react/no-array-index-key
                    key={d}
                    className={classnames('ctc-mc__code-digit', {
                      'ctc-mc__code-digit--highlighted': !instantWin || instantWin.highlighted?.[g][d],
                    })}
                  >
                    {digit}
                  </span>
                ))}
              </span>
            ))}
            {instantWin && (
              <span className="ctc-mc__code-iw">{instantWin.description}</span>
            )}
          </div>
        </div>
      </div>
    ))}
  </FlipMove>
);

CodeList.propTypes = {
  codes: PropTypes.instanceOf(Array).isRequired,
  visibleCodes: PropTypes.number.isRequired,
};

export default CodeList;
