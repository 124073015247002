import PropTypes from 'prop-types';

const TeamLabel = ({ finalEvent, team }) => ((finalEvent && !finalEvent.disableScoreboard) ? (
  <span className={`ctc-final-event__team-${team}`}>
    {finalEvent[`team${team}Description`]}
  </span>
) : null);

TeamLabel.propTypes = {
  finalEvent: PropTypes.instanceOf(Object).isRequired,
  team: PropTypes.string.isRequired,
};

export default TeamLabel;
