import PropTypes from 'prop-types';
import Banners from 'bv-banners';
import Steps from './steps';
import Buttons from './buttons';
import FinalEvent from './final_event';

const FinalPrize = ({ competition, codeList }) => (
  <>
    <div className="site-top-banner-wrapper">
      <Banners location={competition.competitionType === 'RAFFLE' ? 'RaffleFinalPrize' : 'CtcFinalPrize'} />
    </div>
    <div className="ctc-fp">
      <Steps
        type="FP"
        competition={competition}
      />
      <Buttons
        descriptions={competition.descriptions}
        codesNr={codeList.length}
        type={competition.competitionType}
      />
      <FinalEvent competition={competition} />
    </div>
  </>
);

FinalPrize.propTypes = {
  competition: PropTypes.instanceOf(Object).isRequired,
  codeList: PropTypes.instanceOf(Array).isRequired,
};

export default FinalPrize;
