import PropTypes from 'prop-types';
import EventClock from './event_clock';
import EventCodeGroup from './event_code_group';
import TeamLabel from './team_label';

const FinalEvent = ({ competition }) => {
  const {
    sportId, descriptions, finalEvent, competitionType, winningCode, codesConfiguration,
  } = competition;

  return (
    <div className={`ctc-final-event ctc-final-event--sport-${sportId}`}>
      {competitionType !== 'RAFFLE' && (
        <div className="ctc-final-event__title">
          {finalEvent?.eventDescription}
        </div>
      )}
      <div className="ctc-final-event__vs">
        <TeamLabel team="A" finalEvent={finalEvent} />
        <EventClock competition={competition} />
        <TeamLabel team="B" finalEvent={finalEvent} />
      </div>
      <div className="ctc-final-event__code">
        {codesConfiguration?.groups?.map((group, i) => (
          <EventCodeGroup
            key={group.order}
            type={competitionType}
            group={group}
            codeGroup={winningCode && winningCode[i]}
          />
        ))}
      </div>
      <div className={`ctc-final-event__note with-${codesConfiguration.groups.length}-codes`}>
        {descriptions.CTC_FP_EXAMPLE_CODE}
      </div>
    </div>
  );
};

FinalEvent.propTypes = {
  competition: PropTypes.instanceOf(Object).isRequired,
};

export default FinalEvent;
